import React from 'react';
import SVG from './SVG';
const CropShape = ({ width = 385, height = 330, fill = '#fff' }) => (
  <SVG viewBox="0 0 385 330" width={width} height={height} title="Shape">
    <path
      fill={fill}
      d="M385 214.002V330H0s5.159-.685 15.749-1.482C118.589 320.767 219.315 274.075 385 0v214.002z"
    />
  </SVG>
);

export default CropShape;
