import React, { FC } from 'react';
import CropShape from 'src/components/svg/CropShape';
import Query from 'src/utils/Queries';
import tw, { css, styled } from 'twin.macro';

interface GridProps {
  direction?: string;
  header?: boolean;
  fillSpace?: boolean;
}

interface SpacerProps {
  empty?: boolean;
}
export const MaxWidth = styled('div')`
  margin: 0 auto;
  ${Query.tablet} {
    max-width: 768px;
    padding: 0 28px;
  }
`;

export const Wrapper = styled('main')`
  ${tw`
    flex
    flex-col
  `};
  min-height: 100%;
`;

export const SiteHeader = styled('header')`
  ${tw`flex top-0 bg-black left-0 right-0 border-0 border-b bg-black border-solid border-grey-dark flex items-center`};
  height: 56px;
  position: sticky;
  flex: 0 0 auto;
  z-index: 2;
  ${Query.tablet} {
    height: 64px;
  }
`;

export const GridWrap = styled('div')<GridProps>`
  width: 100%;
  flex: 1;
  display: flex;
  ${({ header }) =>
    !header &&
    css`
      overflow: hidden;
    `}
`;

export const GridRestrict = styled.div<GridProps>`
  ${tw`
    flex
    flex-col
    w-full
    mx-auto
    flex-auto
    relative
  `};

  max-width: 375px;
  padding: 0 12px;
  margin: 0 auto;

  ${Query.tablet} {
    max-width: 560px;
    ${({ header }) =>
      header &&
      css`
        max-width: 810px;
      `}
  }
  ${({ direction }) =>
    direction === 'row' &&
    css`
      flex-direction: row;
    `}
  ${({ header }) =>
    header &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
`;

interface PageProps {
  medium?: boolean;
}

// This isn't the case on every page, so we export it and use it
// on an interface level.
export const Page = styled('div')<PageProps>`
  ${tw`
    flex
    flex-auto
    w-full
    flex-col
  `};
  margin: 16px auto;
  ${Query.tablet} {
    ${({ medium }) =>
      medium
        ? css`
            margin: 32px auto;
          `
        : css`
            margin: 48px auto;
          `}
  }
`;

export const PageNoTop = styled.div`
  ${tw`
    flex
    flex-auto
    w-full
  `};
  flex: 1 1 auto;
  margin: 0 auto 16px;
  ${Query.tablet} {
    margin: 0 auto 48px;
  }
`;

export const PageQuestion = styled.div`
  ${tw`
    flex
    flex-auto
    w-full
  `};
  margin: 0 auto;
`;
export const Spacer = styled.div<SpacerProps>`
  width: ${({ empty }) => (empty ? '1px' : '64px')};
`;

export const ImageHeaderStyle = styled.header`
  margin: 0 auto;
  max-width: 810px;
  overflow: hidden;
  position: relative;
  width: 100%;
  img {
    position: absolute;
    top: 50%;
    max-width: initial;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  svg {
    position: absolute;
    z-index: 1;
    right: -1px;
    bottom: 0;
  }
`;

export const ImageHeader: FC<{ className?: string; children: any }> = ({
  className,
  children
}) => (
  <ImageHeaderStyle className={className}>
    {children}
    <CropShape width={235} height={201} fill="#000" />
  </ImageHeaderStyle>
);

export const WelcomeHeader = styled(ImageHeader)`
  height: 280px;
  ${Query.tablet} {
    height: 349px;
  }
`;
export const ModuleHeader = styled(ImageHeader)`
  ${Query.tablet} {
    height: 200px;
  }
`;
