// import { Form as FormikForm } from 'formik';
import ZIndex from 'src/styles/ZIndex';
import { styled, theme } from 'twin.macro';
interface ContentProps {
  onClick?: any;
}

export const Header = styled.header``;

export const FullSVG = styled.svg`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
`;
export const ModalPre = styled.div`
  text-align: left;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background: #181818;
  z-index: 1;
`;

export const Modal = styled.div<ContentProps>`
  text-align: left;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  z-index: ${ZIndex.modal};
`;

export const Scroller = styled.div`
  height: 100%;
  overflow: auto;
`;

export const Margin = styled.div`
  margin: 0 20px;
`;

export const Content = styled.div<ContentProps>`
  height: 100vh;
  overflow: auto;
  position: relative;
`;

export const Overlay = styled.div<ContentProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.8;
  left: 0;
`;

export const Overflow = styled.div`
  background: black;
  border-radius: 15px;
  padding: 103px 16px 24px;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    background: ${theme`colors.grey-dark`};
    height: 1px;
  }
`;

export const Body = styled.section`
  font-weight: 400;
  width: 100%;
  margin: 0 auto;
  max-width: 533px;
  color: white;
  img {
    float: right;
    margin: 0 0 16px 16px;
  }
  h2 {
    color: ${theme`colors.blue`};
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 24px;
  }
`;

export const Centered = styled.div`
  text-align: center;
  color: white;
  a {
    color: white;
  }
`;

export const CloseButton = styled.button<ContentProps>`
  position: absolute;
  padding: 20px 16px;
  top: 0px;
  right: 0px;
  svg {
    display: block;
    margin: 0 auto;
  }
`;
