import { Link } from 'gatsby';
import React, { FC } from 'react';
import Query from 'src/utils/Queries';
import tw, { styled, theme } from 'twin.macro';

interface CurrentModuleWrapProps {
  as: string | typeof Link;
  to: string | boolean;
}
const CurrentModuleWrap = styled(Link)<CurrentModuleWrapProps>`
  ${tw`
  text-white
  no-underline
  uppercase
  font-heading
  block
  relative
`}
  line-height:19px;
  padding: 0 19px;
  font-size: 15px;
  vertical-align: middle;
  span {
    ${tw`
      inline-block
      underline
      text-grey
      ml-1
    `}
  }
  strong {
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    display: inline-block;
    vertical-align: middle;
  }
  &:before,
  &:after {
    content: ' ';
    ${tw`
      absolute
      block
      left-0
      bottom-0
      rounded-large
    `}
    top: -5px;
    width: 15px;
    height: 30px;
    border: 2px solid ${theme`colors.blue`};
  }
  &:before {
    border-radius: 100px 0 0 100px;
    border-right: 0;
  }
  &:after {
    border-left: 0;
    border-radius: 0 100px 100px 0;
    left: auto;
    right: 0;
  }
  ${Query.tablet} {
    strong {
      max-width: none;
    }
  }
`;

const ModuleSwitcher: FC<{ module: string; canChange?: boolean }> = ({
  module,
  canChange
}) => {
  return (
    <CurrentModuleWrap
      as={!canChange ? 'div' : Link}
      to={canChange ? '/app' : false}
    >
      <strong>{module}</strong>
      {canChange && <span>Change</span>}
    </CurrentModuleWrap>
  );
};

export default ModuleSwitcher;
