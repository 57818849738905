import { createSelector } from 'reselect';
import { RootState } from './reducers';

// Auth
export const userEmailSelector = (state: any) => state.auth.email;
export const userNameSelector = (state: any) => state.auth.name;
export const loggedInSelector = createSelector(
  [userEmailSelector, userNameSelector],
  (email, name) => {
    return email !== '' && name !== '';
  }
);

// Answers
export const answersSelector = (state: RootState) => state.answers.answers;
export const completeModulesSelector = (state: RootState) =>
  state.answers.completeModules;
