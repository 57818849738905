import tw, { css, styled, theme } from 'twin.macro';

export const P = styled.p<{ large: boolean }>`
  ${tw`font-heading`};
  ${({ large }) =>
    large &&
    css`
      margin-bottom: 24px;
      font-size: 118.75%;
    `}
`;

export const PageTitle = styled.h1`
  text-shadow: ${theme`boxShadow.blue`};
  text-transform: uppercase;
`;
export const QuestionCategory = styled.h4`
  text-shadow: ${theme`boxShadow.blue`};
  text-transform: uppercase;
  margin-bottom: 8px;
`;
