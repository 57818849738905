import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { animated, useSpring } from 'react-spring';
import Close from 'src/components/svg/Close';
import * as M from './styles';

const Modal: FC<{
  children: ReactNode;
  show: boolean;
  closeCallback: Function;
  closeDisabled?: boolean;
}> = ({ show, children, closeCallback, closeDisabled }) => {
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    if (show === true) {
      setIsOn(true);
      // document.body.classList.add('o-hidden');
    } else {
      setIsOn(false);
      // document.body.classList.remove('o-hidden');
    }
  }, [show]);

  const preventProp = useCallback(e => {
    e.stopPropagation();
  }, []);

  const modalSpring = useSpring({
    from: { opacity: 0, transform: 'translate(0,100%)' },
    transform: show ? 'translate(0,0)' : 'translate(0,100%)',
    opacity: show ? 1 : 0,
    delay: show ? 300 : 0
  });
  const otherSpring = useSpring({
    from: { transform: 'translate(0,100%)' },
    transform: show ? 'translate(0,0)' : 'translate(0,100%)',
    delay: show ? 0 : 300
  });

  return (
    <RemoveScroll enabled={isOn} noIsolation removeScrollBar>
      <M.ModalPre as={animated.div} style={otherSpring} />

      <M.Modal as={animated.div} style={modalSpring}>
        <M.Overlay />
        <M.Content id="closeModalWithBackground">
          <M.Scroller>
            <M.Overflow onClick={preventProp}>
              <M.Body>{children}</M.Body>
              <M.CloseButton
                title="Close"
                aria-label="Close modal"
                disabled={closeDisabled ? closeDisabled : false}
                onClick={closeCallback}
                id="closeModalWithButton"
              >
                <Close />
              </M.CloseButton>
            </M.Overflow>
          </M.Scroller>
        </M.Content>
      </M.Modal>
    </RemoveScroll>
  );
};

export default Modal;
