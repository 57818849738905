// import Query from 'src/utils/Queries';
import tw, { styled } from 'twin.macro';

export const Center = styled.div`
  ${tw`
    flex
    flex-col
    items-center
    text-center
    flex-auto
  `};
`;

export const PushToBottom = styled.div`
  margin-top: auto;
`;
