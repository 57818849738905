import React from 'react';
import SVG from './SVG';
const ChevronLeft = () => (
  <SVG width={24} height={24} viewBox="0 0 24 24">
    <path
      fill="#FFFFFF"
      d="M15,3.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,0.9,0.1,1.3l-0.1,0.1L9.2,12l7.3,7.3c0.4,0.4,0.4,0.9,0.1,1.3
	l-0.1,0.1c-0.4,0.4-0.9,0.4-1.3,0.1L15,20.7l-8-8c-0.4-0.4-0.4-0.9-0.1-1.3L7,11.3C7,11.3,15,3.3,15,3.3z"
    />
  </SVG>
);

export default ChevronLeft;
