import React from 'react';
import SVG from './SVG';
const Close = ({ fill = '#fff' }) => (
  <SVG width={24} height={24} viewBox="0 0 24 24">
    <path
      fill={fill}
      d="M19.256 4.744c.3.3.323.773.07 1.1l-.07.079L13.178 12l6.078 6.077c.325.326.325.853 0 1.179-.3.3-.773.323-1.1.07l-.079-.07L12 13.178l-6.077 6.078c-.326.325-.853.325-1.179 0-.3-.3-.323-.773-.07-1.1l.07-.079L10.822 12 4.744 5.923c-.325-.326-.325-.853 0-1.179.3-.3.773-.323 1.1-.07l.079.07L12 10.822l6.077-6.078c.326-.325.853-.325 1.179 0z"
    />
  </SVG>
);

export default Close;
