import { Form as FormikForm } from 'formik';
import tw, { css, styled, theme } from 'twin.macro';

interface Props {
  error?: boolean;
}

export const baseInputStyles = css<Props>`
  ${tw`
    bg-grey-x-dark
    border
    border-solid
    border-grey
    font-body
    font-light
    text-white
    rounded-large
    text-base
    p-3
  `}
  ${({ error }) => error && tw`border-error! border-2!`}

  &:active,
  &:focus {
    box-shadow: 0 0 9px 0 ${theme`colors.blue`};
    outline: 0;
  }
`;

export const InputWrap = styled.div<{ hasIcon: boolean }>`
  ${tw`
    relative
    mt-2
  `};
  ${({ hasIcon }) =>
    hasIcon &&
    css`
      svg {
        position: absolute;
        top: 12px;
        left: 12px;
        right: 12px;
      }
    `}
`;

export const Label = styled.label`
  ${tw`
    font-heading
    text-white
  `};

  font-size: 1.0625rem;
  margin-bottom: 6px;
`;

export const PlaceholderText = styled('p')`
  ${tw`
    text-grey-light
    mb-0
  `};

  font-size: 0.9375rem;
`;

export const HelpText = styled.p<Props>`
  ${tw`
    text-sm
    mt-2
    mb-0
    text-right
  `}

  ${({ error }) => error === true && tw`text-error`}
`;

interface FormProps {
  hasBottom?: boolean;
  maxWidth?: boolean;
}

export const Form = styled(FormikForm)<FormProps>`
  ${tw`
    flex
    flex-col
    w-full
  `}

  ${({ hasBottom }) =>
    hasBottom
      ? css`
          flex: 1 1 auto;
        `
      : css`
          flex: 0 1 auto;
        `};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: 348px;
      align-items: center;
    `};

  margin: 5px auto 40px;
  button[type='submit'] {
    margin-top: 12px;
  }
`;

export const InputGroup = styled.div`
  ${tw`
    mb-4
    w-full
  `};
`;

export const FormBottom = styled.div`
  margin-top: auto;
`;
