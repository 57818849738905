import React, { FC, ReactElement } from 'react';
import { styled } from 'twin.macro';

const Button = styled.button`
  padding: 20px;
  background: none;
  svg {
    display: block;
  }
`;

export const IconButton: FC<{
  title?: string;
  to?: string;
  as?: any;
  icon: ReactElement;
  onClick: unknown;
}> = ({ as, to, title, icon, onClick }) => {
  return (
    <Button as={as} onClick={onClick} to={to} title={title}>
      {icon}
    </Button>
  );
};

export default IconButton;
