import tw, { css, styled } from 'twin.macro';

interface LinkButtonProps {
  small?: boolean;
}
export const LinkButton = styled.button<LinkButtonProps>`
  ${tw`
    font-heading
    font-hairline
    uppercase
    text-grey
    active:text-white
    hover:cursor-pointer
    underline
  `};
  letter-spacing: -0.2px;
  appearance: none;
  &:focus {
    outline: 0;
  }
  font-size: 17px;
  ${({ small }) =>
    small &&
    css`
      ${tw`
    normal-case
  `};
      font-size: 15px;
    `}
`;

export default LinkButton;
