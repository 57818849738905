// import { Link } from 'gatsby';
import React, { FC, ReactNode, useCallback } from 'react';
import IconButton from 'src/components/IconButton';
import ChevronLeft from 'src/components/svg/ChevronLeft';
import Logo from 'src/components/svg/Logo';
import * as L from 'src/styles/Layout';
import CurrentModule from './CurrentModule';

export const Grid: FC<{
  direction?: string;
  header?: boolean;
  children: ReactNode;
}> = ({ direction, header, children }) => (
  <L.GridWrap header={header}>
    <L.GridRestrict direction={direction} header={header}>
      {children}
    </L.GridRestrict>
  </L.GridWrap>
);

export const Body: FC<{
  children: ReactNode;
  module?: string;
  experience?: boolean;
  experienceComplete?: boolean;
  backLink?: boolean;
}> = ({ children, module, experience, backLink = false }) => {
  const goBack = useCallback(() => {
    if (window) {
      window.history.back();
    }
  }, []);

  return (
    <L.Wrapper>
      <L.SiteHeader>
        <Grid direction="row" header>
          {experience ? (
            <>
              {backLink ? (
                <IconButton
                  title="Go back"
                  icon={<ChevronLeft />}
                  onClick={goBack}
                />
              ) : (
                <L.Spacer empty />
              )}
              {module && <CurrentModule module={module} />}
              <L.Spacer empty={backLink === false} />
            </>
          ) : (
            <>
              <Logo />
              {module && <CurrentModule module={module} canChange />}
            </>
          )}
        </Grid>
      </L.SiteHeader>
      {children}
      {/* <StartAgain /> */}
    </L.Wrapper>
  );
};

export default Body;
