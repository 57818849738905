import React, { FC } from 'react';
import tw, { styled, theme } from 'twin.macro';
import Button from './Button';
import Check from './svg/Check';

export const IconButton = styled(Button)`
  ${tw`
    rounded-full
  `}

  min-width: 72px;
  width: 72px;
  height: 72px;
  transition: transform 250ms;

  &:before,
  &:after {
    ${tw`
    rounded-full
  `}
    width:100%;
  }
  &:before {
    clip-path: inset(-8px 38px -8px -8px);
  }
  &:after {
    clip-path: inset(-8px -8px -8px 38px);
  }
  svg {
    fill: ${theme`colors.white`};
  }

  &:active {
    transform: scale(${80.0 / 72.0});
    svg {
      fill: ${theme`colors.grey`};
      transform: scale(${72.0 / 80.0});
    }
  }
`;

export const CheckIconButton: FC<{
  as?: any;
  to?: string;
  glow?: string;
  disabled?: boolean;
  onClick?: unknown;
  type?: string;
}> = ({ as, to, type, glow, disabled, onClick }) => {
  return (
    <IconButton
      disabled={disabled}
      icon
      type={type}
      onClick={onClick}
      glow={glow}
      as={as}
      to={to}
    >
      <Check />
    </IconButton>
  );
};

export default CheckIconButton;
