import tw, { css, styled } from 'twin.macro';
interface ButtonProps {
  small?: boolean;
  disabled?: boolean;
  glow?: string;
  fullWidth?: boolean;
}
export const Button = styled.button<ButtonProps>`
  ${tw`
    font-heading
    font-hairline
    uppercase
    text-white
    bg-transparent
    inline-flex
    justify-center
    items-center
    relative
    rounded
    transition
    duration-200
    active:text-grey
    hover:cursor-pointer
    no-underline
  `};
  width: 100%;

  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '346px')};

  ${({ small }) =>
    small ? tw`h-10 border text-base` : tw`h-12 border-2 text-lg`};

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: '';
    width: 50%;
    ${tw`
        top-0
        bottom-0
        block
        absolute
        rounded
        border-blue
        border-solid
      `};
    ${({ small }) => (small ? tw`border` : tw`border-2`)};
  }

  &:before {
    clip-path: inset(-8px 3px -8px -8px);
    ${tw`
        left-0
        border-r-0
      `}
  }
  &:after {
    clip-path: inset(-8px -8px -8px 3px);
    ${tw`
        right-0
        border-l-0
      `}
  }

  ${({ glow }) =>
    glow &&
    css`
      &:before,
      &:after {
        ${tw`
        border-white
      `};
      }
      &[disabled] {
        ${tw`
          border-grey-dark
        `}
      }
    `}
  ${({ glow }) =>
    glow === 'blue' &&
    css`
      &[disabled] {
        &:before,
        &:after {
          box-shadow: none;
        }
      }
      &:before {
        ${tw`
          shadow-blue-left
        `};
      }
      &:after {
        ${tw`
          shadow-blue-right
        `}
      }
    `}
    ${({ glow }) =>
    glow === 'orange' &&
    css`
      &[disabled] {
        &:before,
        &:after {
          box-shadow: none;
        }
      }
      &:before {
        ${tw`
          shadow-orange-left
        `};
      }
      &:after {
        ${tw`
          shadow-orange-right
        `}
      }
    `}

    &[disabled] {
    cursor: not-allowed;
    &:before,
    &:after {
      ${tw`
          border-grey-dark
        `}
    }
  }
`;

export default Button;
