const dataLayerPush = (obj: any) => {
  // @ts-ignore
  if (window.dataLayer) {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push(obj);
  }
};

export default dataLayerPush;
